import {Employee} from '../../../app/modules/employee/models/Employee.model';
import {SkeModal} from '../../../common/modals/generic/SkeModal';
import React, {ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {disciplineModalSlice} from '../discipline-modal.slice';
import {EmployeeBadge, PersonBadgeOpts} from '../../../app/components/EmployeeBadge';
import {AvatarSize} from '../../../app/components/Avatar';
import {RootState} from '../../../setup';
import {User, UserRoleEnum} from '../../../app/modules/user/models/User.model';
import {catchError, combineLatest, concatMap, filter, map, Observable, of, Subject, takeUntil, tap} from 'rxjs';
import {
	Discipline,
	DisciplineActions,
	DisciplineCreate,
	DisciplineStatusActions,
	DisciplineStatusEnum,
	DisciplineStatusForHumansEnum,
	DisciplineTextFields,
	DisciplineTextFieldType,
} from '../../../app/modules/discipline/models/Discipline.models';
import {DisciplineStep, DisciplineStepTypeEnum} from '../../../app/modules/discipline/models/DisciplineStep.models';
import {Attendance} from '../../../app/modules/attendance/models/Attendance.models';
import {CategoryModel} from '../../category/interfaces/category.model';
import {CategorySubtypeEnum, TemplateModel} from '../../../app/modules/performance/models/PerformanceCategory.models';
import {disciplineStepSlice} from '../discipline-step.api';
import {ConnectionTypeEnumWithCa} from '../../connection/interfaces/connection.model';
import {ConnectionTypeEnum} from '../../../app/types/ConnectionType';
import {sortDateDescending} from '../../../_library/helpers/sortDate';
import {getSuperAdmins, getUsers} from '../../../app/modules/user/services/User.service';
import {compact, entries, intersection, isUndefined, omit, uniq, values} from 'lodash';
import {getPerformanceCategories} from '../../../app/modules/performance/services/PerformanceCategory.service';
import {
	createDiscipline,
	deleteDiscipline,
	getEmployeeDisciplines,
	getOneDiscipline,
	updateDiscipline,
} from '../../../app/modules/discipline/services/Discipline.service';
import {SortDateEnum} from '../../../app/types/SortDateEnum';
import {getEmployeeAttendances} from '../../../app/modules/attendance/services/Attendance.service';
import moment from 'moment/moment';
import {DateFormatUsingDateFns, SHORT_DATE} from '../../../app/modules/date/DateFormat.const';
import {toast} from 'react-toastify';
import {format} from 'date-fns';
import invariant from 'tiny-invariant';
import {Entries} from 'type-fest';
import {Company} from '../../../app/modules/company/models/Company.model';
import {ToggleSwitch} from '../../../app/components/ToggleSwitch';
import {DatePicker} from '../../../app/components/DatePicker';
import {SkeTextbox} from '../../../app/components/Textbox';
import clsx from 'clsx';
import {Icon} from '../../../app/components/icons/Icon';
import {Printer, Refresh, Save, Trash} from '../../../app/components/icons/IconList';
import {SkeDialogDelete} from '../../../common/components/dialog-delete';
import './DisciplineModal.scss';
import './DisciplinePrint.scss';
import ReactToPrint from 'react-to-print';
import {Team} from '../../../app/modules/team/models/Team.model';

const expand = require('expand-template')();
interface Props {
	show: boolean;
	employee: Employee;
	onSubmit: () => void;
	onClose?: (action: boolean) => void;
	disciplineId?: number,
	modalId?: string;
}

interface DisciplineActionStep {
	order: number | null;
	label: string;
	desc?: string;
	status?: DisciplineStatusEnum;
}

type EditableSections = 'body'|'owner'|'reviewer'|'status'|'comments'|'refusalToSign';

const initialValues = {
	selectedDisciplineStep: null,
	currentDisciplineType: DisciplineStepTypeEnum.Performance,
	priorDisciplines: [],
	priorAttendances: [],
	combinedInfractions: [],
	dismissedInfractions: [],
	selectedTemplate: undefined,
};

const emptyDiscipline: Pick<Discipline, 'status' | 'textFields' | 'creatorId' | 'reviewerId' | 'changes' | 'isSigned' | 'misc' > = {
	textFields: {
		incidentDetails: '',
		expectations: '',
		consequences: '',
	},
	status: DisciplineStatusEnum.Draft,
	// categoryId: 0,
	// templateId: 0,
	creatorId: 0,
	reviewerId: 0,
	changes: '',
	isSigned: false,
	misc: {
		comments: '',
		employeeRefusedToSign: false
	}
};

const generateTemplatePlaceholderValues = (opts: {
	emp: Employee,
	disc?: Partial<Discipline>,
	latestAtt?: Attendance,
	priorDisc?: Discipline,
	cmp: Company
}) => {
	return {
		employeeFullName: `${opts.emp.firstName} ${opts.emp.lastName}`,
		employeeFirstName: opts.emp.firstName,
		employeeLastName: opts.emp.lastName,
		employeeHireDate: opts.emp.hireDate,
		currentDisciplineStepName: opts.disc?.step?.name || '{Discipline Step Name}',
		currentDisciplineDate: opts.disc?.date || '{Discipline Date}',
		currentDisciplineSupervisorNote: opts.disc?.supervisorNote || '{Supervisor Notes}',
		currentAttendancePoints: opts.emp.currentAttendancePoints,
		latestAttendanceDate: opts.latestAtt?.date || '{Latest Attendance Date}',
		latestAttendancePoints: opts.latestAtt?.pointsAssigned || '{Points Assigned}',
		companyName: opts.cmp.name,
		// TODO: fix interface so don't have to override - can't do optional because TS think it's an array of numbers
		locationName: opts.emp?.teams?.[0]?.location,
	};
};

const shrinkInfractionForSaving = (inf: (Discipline|Attendance)): any => {
	const filteredInfraction: any = {
		id: inf.id,
		date: inf.date,
		connectionType: inf.connectionType,
		creator: (!inf.creator) ? null : {
			id: inf.creator.id,
			firstname: inf.creator.firstName,
			lastName: inf.creator.lastName,
		},
	};

	if (inf.connectionType === 'DISCIPLINE') {
		filteredInfraction.status = inf.status;
		filteredInfraction.closed_at = inf.closed_at;
		filteredInfraction.templateId = inf.templateId;
		filteredInfraction.stepId = inf.step.id;
	}
	if (inf.connectionType === 'ATTENDANCE') {
		filteredInfraction.occurrence = inf.occurrence;
		filteredInfraction.pointsAssigned = inf.pointsAssigned;
	}

	return filteredInfraction;
}

const CA_ACTIONS: {[value in DisciplineActions]: DisciplineActionStep} = {
	// non-status actions
	[DisciplineActions.Delete]: {
		order: null,
		label: 'Delete Draft',
	},
	[DisciplineActions.Print]: {
		order: null,
		label: 'Print',
	},
	[DisciplineActions.Save]: {
		order: null,
		label: 'Save',
	},
	// status change actions
	[DisciplineActions.NoChange]: {
		order: 0,
		label: 'No status change',
		desc: 'Save updated info or reviewer/owner without changing the status'
	},
	[DisciplineActions.Draft]: {
		order: 1,
		label: 'Draft',
		desc: 'Save work in progress to resume later',
		status: DisciplineStatusEnum.Draft,
	},
	[DisciplineActions.Decline]: {
		order: 2,
		label: 'Decline',
		desc: 'Decline and close',
		status: DisciplineStatusEnum.DisciplineDeclined,
	},
	[DisciplineActions.RequestChanges]: {
		order: 3,
		label: 'Request Changes',
		desc: 'Send back to supervisor to request changes',
		status: DisciplineStatusEnum.RequestedChange,
	},
	[DisciplineActions.Approve]: {
		order: 4,
		label: 'Approve',
		desc: 'Approve and send back to supervisor for issuance',
		status: DisciplineStatusEnum.Approved,
	},
	[DisciplineActions.RequestApproval]: {
		order: 5,
		label: 'Request Approval',
		status: DisciplineStatusEnum.RequestedApproval,
	},
	[DisciplineActions.Issue]: {
		order: 6,
		label: 'Issue',
		desc: 'Click when issued to employee',
		status: DisciplineStatusEnum.IssuedToEmployee,
	},
	[DisciplineActions.Submit]: {
		order: 7,
		label: 'Submit',
		desc: 'Click when submitted to HR',
		status: DisciplineStatusEnum.SubmittedToHr,
	},
	[DisciplineActions.Receive]: {
		order: 8,
		label: 'Received',
		desc: 'Click when received by HR',
		status: DisciplineStatusEnum.DocumentationFiled,
	},
	[DisciplineActions.FastFile]: {
		order: 9,
		label: 'Fast File',
		desc: 'Click to immediately file in last status',
		status: DisciplineStatusEnum.DocumentationFiled,
	},
};

const CA_NEXT_STEP: {[key in DisciplineStatusEnum]: DisciplineStatusActions | null} = {
	[DisciplineStatusEnum.Draft]: DisciplineActions.RequestApproval,
	[DisciplineStatusEnum.RequestedChange]: DisciplineActions.RequestApproval,
	[DisciplineStatusEnum.RequestedApproval]: DisciplineActions.Approve,
	[DisciplineStatusEnum.DisciplineDeclined]: null,
	[DisciplineStatusEnum.Approved]: DisciplineActions.Issue,
	[DisciplineStatusEnum.IssuedToEmployee]: DisciplineActions.Submit,
	[DisciplineStatusEnum.SubmittedToHr]: DisciplineActions.Receive,
	// this is in place, but is being skipped. WHen superuser marks something received, it's assumed that it's been
	// filed and is updated in the api accordingly.
	[DisciplineStatusEnum.ReceivedByHr]: null,
	[DisciplineStatusEnum.DocumentationFiled]: null,
}

export function DisciplineModal({
																	employee,
																	disciplineId,
																	show,
																	onSubmit,
																	onClose,
																}: Props ){

	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [showModal, setShowModal] = useState<boolean>(show);
	const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User;
	const correctiveActionPrintRef = useRef<HTMLButtonElement>(null)
	const closeButtonRef = useRef<HTMLButtonElement>(null)
	const destroy$: Subject<boolean> = new Subject<boolean>();
	const [discipline, setDiscipline] = useState<Partial<Discipline> & Pick<Discipline, 'status'>>(emptyDiscipline);
	const [currentDisciplineType, setCurrentDisciplineType] = useState<DisciplineStepTypeEnum.Attendance|DisciplineStepTypeEnum.Performance>(DisciplineStepTypeEnum.Performance);
	const [priorDisciplines, setPriorDisciplines] = useState<Discipline[]>([]);
	const [priorAttendances, setPriorAttendances] = useState<Attendance[]>([]);
	const [combinedInfractions, setCombinedInfractions] = useState<(Discipline|Attendance)[]>([]);
	const [category, setCategory] = useState<CategoryModel>();
	const [savedDisciplineCopy, setSavedDisciplineCopy] = useState<Discipline>();
	const [dismissedInfractions, setDismissedInfractions] = useState<(Attendance|Discipline)[]>([]);
	const [reviewers, setReviewers] = useState<User[]>([]);
	const [owners, setOwners] = useState<User[]>([]);
	const [selectedOwner, setSelectedOwner] = useState<User>();
	const [categories, setCategories] = useState<CategoryModel[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel>();
	const [disciplineDate, setDisciplineDate] = useState<Date>(new Date());
	const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);
	const [showAttendanceInfractions, setShowAttendanceInfractions] = useState<boolean>(false);
	const [isFutureDateSelected, setIsFutureDateSelected] = useState<boolean>(false);
	const { data: disciplineSteps } = disciplineStepSlice.useGetDisciplineStepsQuery({});
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [areTextFieldsUntouched, setAreTextFieldsUntouched] = useState<boolean>(true);
	const [areTemplateDependenciesAltered, setAreTemplateDependenciesAltered] = useState<boolean>(false);
	const [availableActions, setAvailableActions] = useState<DisciplineActions[]>([]);
	const [selectedAction, setSelectedAction] = useState<DisciplineActionStep | null>(null);
	const [changesRequested, setChangesRequested] = useState<string>();
	const [editableSections, setEditableSections] = useState<EditableSections[]>([]);
	const [showErrors, setShowErrors] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const dispatch = useDispatch();
	const pdfCardRef = useRef<HTMLDivElement>(null);
	const [disciplineComments, setDisciplineComments] = useState<string>();
	const [employeeRefusedToSign, setEmployeeRefusedToSign] = useState(false);

	const resetModal = () => {
		setDisciplineComments('')
		setDiscipline(emptyDiscipline);
		setCurrentDisciplineType(initialValues.currentDisciplineType);
		setPriorDisciplines(initialValues.priorDisciplines);
		setPriorAttendances(initialValues.priorAttendances);
		setCombinedInfractions(initialValues.combinedInfractions);
		setCategory(undefined);
		setDismissedInfractions(initialValues.dismissedInfractions);
		setSelectedTemplate(initialValues.selectedTemplate);
		setSelectedAction(null);
		setShowPrintPreview(false);
		setIsFutureDateSelected(false);
		setAreTextFieldsUntouched(true);
		setAreTemplateDependenciesAltered(false);
	};

	const handleHidePriorInfraction = (infractionToHide: (Attendance|Discipline)) => {
		setDismissedInfractions([
			...dismissedInfractions,
			infractionToHide,
		]);
	}

	const handleResetDismissedInfractions = () => {
		setDismissedInfractions([]);
	}

	const getFilteredPriorInfractions = (): (Discipline|Attendance)[] => {
		const dismissedDisciplineIds = dismissedInfractions
			.filter(itm => [ConnectionTypeEnumWithCa.Discipline, ConnectionTypeEnumWithCa.CorrectiveAction].includes(itm?.connectionType as unknown as ConnectionTypeEnumWithCa))
			.map(itm => itm.id);
		const dismissedAttendanceIds = dismissedInfractions
			.filter(itm => itm.connectionType === ConnectionTypeEnum.Attendance)
			.map(itm => itm.id);

		return combinedInfractions
			.filter(infraction => {
				if (infraction.connectionType === ConnectionTypeEnum.Attendance) {
					if (!showAttendanceInfractions) {
						return false;
					}
					return !dismissedAttendanceIds.includes(infraction.id);
				}
				if ([ConnectionTypeEnumWithCa.Discipline, ConnectionTypeEnumWithCa.CorrectiveAction].includes(infraction?.connectionType as unknown as ConnectionTypeEnumWithCa)) {
					return !dismissedDisciplineIds.includes(infraction.id);
				}
			});
	};

	const infractionsToDisplay = useMemo(() => getFilteredPriorInfractions().sort(sortDateDescending),
		[priorAttendances, priorDisciplines, dismissedInfractions, showAttendanceInfractions]);

	const combinePriorInfractions = (disciplines: Discipline[], attendances: Attendance[]) => {
		const newCombinedInfractions = [
			...disciplines,
			...attendances,
		].sort(sortDateDescending);
		setCombinedInfractions(newCombinedInfractions);
		return newCombinedInfractions;
	}

	const getSuperAdminsForReviews = () => {
		return getSuperAdmins(user, token)
			.pipe(
				takeUntil(destroy$),
				map(data => data.items.sort((a: User, b: User) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))),
				tap(setReviewers),
			)
	}



	const loadAndSetPerformanceCategories = (): Observable<any> => {
		return getPerformanceCategories(token, {isArchived: false})
			.pipe(
				takeUntil(destroy$),
				// TODO: CategorySlice take away this override when we build the category feature module and can recognize
				//  it's sending back the TemplateModel. We replaced the performance-category API to use Category table/feature
				tap(cats => setCategories((cats as unknown as CategoryModel[]))),
			)
	}

	const isTemplateSelectionValid = () => !!discipline?.date && !!discipline.templateId;
	const isReviewerValid = () => !!discipline?.reviewer;
	const areTextFieldsValid = () => {
		if (discipline?.textFields === null ||
			(!discipline?.textFields?.incidentDetails && !discipline?.textFields?.expectations && !discipline?.textFields?.consequences)) {
			return false;
		}
		return true;
	}

	useEffect(() => {
		return () => {
			destroy$.next(true);
			resetModal();
		}
	}, []);



	const setupNextStep = (savedDisc?: Discipline) => {
		const availableActions = getAvailableStatusActions(savedDisc);
		const status: DisciplineStatusEnum = savedDisc?.status || DisciplineStatusEnum.Draft;
		const nextStep = CA_NEXT_STEP[status];
		if (!disciplineId) {
			return setSelectedAction(CA_ACTIONS[DisciplineActions.RequestApproval]);
		}
		const sel = nextStep && availableActions?.includes(nextStep) ? CA_ACTIONS[nextStep] : null;
		setSelectedAction(sel);
	};



	useEffect(() => {
		if(selectedOwner){
			const check = owners.find((usr)=>usr.id === selectedOwner.id);
			if(!check){
				let users = owners;
				users.push(selectedOwner);
				setOwners(users);
			}
		}
	}, [selectedOwner]);

	const getUsersForOwnerList = () => {
		getUsers(token).then((res)=>{
			const users = res.data.items.filter((user: User)=>{
				const userTeams = user.teams.map((team: Team)=>{return team.name});
				if(user.roles.includes(UserRoleEnum.SuperAdmin)){
					return user;
				}else{
					if(discipline.team){
						const applicableUser = userTeams.includes(discipline.team.name);
						if(applicableUser){
							return user;
						}
					}else{
						if(employee.teams){
							if(employee.teams.length > 0 ){
								let employeeTeams = employee.teams.map((team: Team)=>{return team.name})
								const applicableUser = userTeams.some(team => employeeTeams.includes(team));
								if(applicableUser){
									return user;
								}
							}
						}
					}
				}
			});
			let filteredUsers = users;
			if(!user.email.includes("secchi.io")){
				filteredUsers = users.filter(usr => !usr.email.includes("secchi.io"))
			}
			setOwners(filteredUsers.sort((a: User, b: User) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)));
		})
	}

	useEffect(() => {
		resetModal();
		if(show) {

			setShowModal(true)
			const disciplines$ = getEmployeeDisciplines(employee.id, token, {sort: SortDateEnum.Desc})
				.pipe(
					takeUntil(destroy$),
					map((res) => {
						return res.items.filter(itm => !discipline || itm.id !== discipline.id);
					}),
					tap(val => setPriorDisciplines(val)),
					tap(val => setCombinedInfractions(prevState => [...prevState, ...val])),
				);

			const attendances$ = getEmployeeAttendances(employee.id, token, {
				sort: SortDateEnum.Desc,
				isExcused: 'false',
				limit: 100,
			})
				.pipe(
					takeUntil(destroy$),
					map(res => res.items),
					tap(val => setPriorAttendances(val)),
					tap(val => setCombinedInfractions(prevState => [...prevState, ...val])),
				);
			if (!disciplineId) {
				setCurrentDisciplineType(DisciplineStepTypeEnum.Performance);
				setDiscipline({...emptyDiscipline, creatorId: user.id});
				combineLatest([
					disciplines$,
					attendances$,
					loadAndSetPerformanceCategories(),
					getSuperAdminsForReviews(),
				])
					.pipe(
						takeUntil(destroy$),
					)
					.subscribe(() => {
						setupNextStep();
						setDismissedInfractions([]);
						setEditableSections(calcEditableSections());
					});
				return;
			}
			// TODO: stubbing this in temporarily to try and bypass a react async update issue
			//  which makes the combined infractions not be available when discipline loads
			let tempCombinedInfractions: (Attendance | Discipline)[] = [];
			const loadSavedDiscipline$ = getOneDiscipline(disciplineId, token)
				.pipe(
					tap(disc => {
						setSavedDisciplineCopy(disc);
						setCurrentDisciplineType(disc.step.type);
						setDisciplineDate(moment(disc.date, SHORT_DATE).toDate());
						setIsFutureDateSelected(moment(disc.date) > moment());
						if (disc.category) {
							setCategory(disc.category);
						}
						if (disc.template) {
							setSelectedTemplate(disc.template);
						}
						if(disc.misc?.comments){
							setDisciplineComments(disc.misc.comments)
						}
						if(disc.misc?.employeeRefusedToSign){
							setEmployeeRefusedToSign(disc.misc?.employeeRefusedToSign)
						}

						setAreTextFieldsUntouched(true);
						setDiscipline(disc);
						setAreTemplateDependenciesAltered(false);
						setEditableSections(calcEditableSections(disc));
					}),
				)
			combineLatest([
				disciplines$,
				attendances$,
				loadSavedDiscipline$,
				loadAndSetPerformanceCategories(),
				getSuperAdminsForReviews(),
			])
				.pipe(
					catchError(err => {
						console.error(err);
						return of(err);
					}),
					takeUntil(destroy$),
					tap(([prvDisc, prvAtt]) => {
						tempCombinedInfractions = combinePriorInfractions(prvDisc, prvAtt);
					}),
					concatMap(() => getOneDiscipline(disciplineId, token)),
					filter(disc => !!disc),
				)
				.subscribe(disc => {

					// merge up past infractions
					if (disc.infractions.length) {
						// already converted in the service to JS objects
						const parsedSavedInfractions = (disc.infractions as unknown as (Discipline | Attendance)[]);
						const parsedSavedDisciplines: Partial<Discipline>[] = [];
						const parsedSavedAttendances: Partial<Attendance>[] = [];
						parsedSavedInfractions.map((item) => {
							switch (item.connectionType) {
								// @ts-ignore - migrating to both DISCIPLINE and CORRECTIVE ACTION
								case ConnectionTypeEnumWithCa.CorrectiveAction:
								case ConnectionTypeEnum.Discipline:
									parsedSavedDisciplines.push(item);
									break;
								case ConnectionTypeEnum.Attendance:
									parsedSavedAttendances.push(item);
									break;
							}
						});
						const infractionsToDismiss = [...dismissedInfractions];
						tempCombinedInfractions.map((item) => {
							let found = true;
							// @ts-ignore - migrating to both DISCIPLINE and CORRECTIVE ACTION
							if ([ConnectionTypeEnumWithCa.Discipline, ConnectionTypeEnumWithCa.CorrectiveAction].includes(item?.connectionType)) {
								found = parsedSavedDisciplines.map(dsc => dsc.id).includes(item.id)
							} else if (item.connectionType === ConnectionTypeEnum.Attendance) {
								found = parsedSavedAttendances.map(att => att.id).includes(item.id)
							}
							if (!found) {
								infractionsToDismiss.push(item);
							}
						});

						setDismissedInfractions(infractionsToDismiss);

					} else {
						if (![DisciplineStatusEnum.Draft, DisciplineStatusEnum.RequestedChange].includes(disc.status)) {
							setDismissedInfractions(tempCombinedInfractions);
						}
					}
					setupNextStep(disc);
				});
		}else{
			setShowModal(false)
		}
	}, [show, disciplineId]);

	const handleDisciplineStepClick = (step: DisciplineStep) => {
		if (!editableSections.includes('body')) {
			console.error(`Cannot edit discipline step as the body of this CA is not currently editable.`);
		} else {
			setAreTemplateDependenciesAltered(!areTextFieldsUntouched);
			if (areTextFieldsUntouched) {
				const updatedTextFields = getUpdatedTextFields({step: step})
				setDiscipline(prevState => ({
					...prevState,
					textFields: updatedTextFields,
					step,
				}))
			}
		}



	};

	const handleChangeReviewer = (e: ChangeEvent<HTMLSelectElement>) => {
		const newReviewer = reviewers.find(user => user.id === +e.target.value);
		if (newReviewer) {
			setDiscipline(prevState => ({
				...omit(prevState, 'reviewer'),
				reviewerId: newReviewer.id,
			}));
		}
	};

	const handleChangeOwner = (e: ChangeEvent<HTMLSelectElement>) => {
		const newOwner = owners.find(user => user.id === +e.target.value);
		if (newOwner) {
			setDiscipline(prevState => ({
				...prevState,
				creatorId: newOwner.id,
			}));
		}
	};

	const handlePreviewDiscipline = async () => {
		setDiscipline(prevState => ({
			...prevState,
			infractions: infractionsToDisplay,
		}));
		// await allows it to update state before displaying, so fixes a race condition
		if (showPrintPreview) {
			setShowPrintPreview(false);
		} else {
			setShowPrintPreview(true);
		}
		// setShowPrintPreview(true);
		// setShowModal(false);
	}

	const handleDisciplineDateChange = (date: Date | null) => {
		if (!!date) {
			setAreTemplateDependenciesAltered(!areTextFieldsUntouched);
			setDisciplineDate(date);
			setIsFutureDateSelected(moment(date) > moment());
			const formattedDate = moment(date).format(SHORT_DATE)
			const updatedTextFields = getUpdatedTextFields({date: formattedDate})
			setDiscipline(prevState => ({
				...prevState,
				date: formattedDate,
				textFields: updatedTextFields
			}));
		}
	}

	const calcEditableSections = (disc?: Discipline): EditableSections[] => {
		const editableSections: EditableSections[] = [];
		const discToCheck = disc || savedDisciplineCopy;
		const isCreator = discToCheck?.creatorId === user.id;
		if (!discToCheck
			|| (
				(
					user.roles.includes(UserRoleEnum.SuperAdmin)
					|| discToCheck?.creatorId === user.id
				)
				&& ![DisciplineStatusEnum.DisciplineDeclined, DisciplineStatusEnum.DocumentationFiled].includes(discToCheck.status)
			)
		) {
			editableSections.push('reviewer', 'owner');
		}
		if (user.roles.includes(UserRoleEnum.SuperAdmin)) {

			// Workflow: Superadmins can edit all the way to document filed

			if (!discToCheck
				|| ![DisciplineStatusEnum.DocumentationFiled, DisciplineStatusEnum.DisciplineDeclined].includes(discToCheck.status)) {
				editableSections.push('body');
			}
			if(discToCheck){
				if(![
					DisciplineStatusEnum.Draft,
					DisciplineStatusEnum.RequestedChange,
					DisciplineStatusEnum.RequestedApproval,
					DisciplineStatusEnum.Approved,
					DisciplineStatusEnum.DisciplineDeclined,
				].includes(discToCheck.status)){
					editableSections.push('refusalToSign');
				}
			}

			// can fast file as long as not already filed or closed
			editableSections.push('status');
		}
		if (user.roles.includes(UserRoleEnum.Supervisor)) {
			// can update date/employee/step/text fields/infractions in draft, requested changes, or approved
			if (!discToCheck
				|| (isCreator && [DisciplineStatusEnum.Draft, DisciplineStatusEnum.RequestedChange, DisciplineStatusEnum.RequestedApproval].includes(discToCheck.status))
			) {
				editableSections.push('body');
			}

			if (!discToCheck
				|| (isCreator && [DisciplineStatusEnum.Draft, DisciplineStatusEnum.RequestedChange, DisciplineStatusEnum.Approved, DisciplineStatusEnum.IssuedToEmployee].includes(discToCheck.status))
			) {
				editableSections.push('status');
			}

			if(discToCheck){
				if(discToCheck.status === DisciplineStatusEnum.IssuedToEmployee){
					editableSections.push('refusalToSign');
				}
			}
		}
		return uniq(editableSections);
	}

	const validateDiscipline = (discipline: Partial<DisciplineCreate>)=> {
		if(!!disciplineId) {
			return true;
		} else {
			if( isUndefined(discipline.category_id)){
				const error = 'Category must be selected';
				let arr = formErrors.filter(e => e !== error);
				arr.push(error);
				setFormErrors(arr);
				setShowErrors(true)
				return false;
			} else if( isUndefined(discipline.templateId)){
				const error = 'Template must be selected';
				let arr = formErrors.filter(e => e !== error);
				arr.push(error);
				setFormErrors(arr);
				setShowErrors(true)
				return false;
			} else if (isUndefined(discipline.stepId)) {
				const error = 'Step must be selected';
				let arr = formErrors.filter(e => e !== error);
				arr.push(error);
				setFormErrors(arr);
				setShowErrors(true);
				return false;
			}
			setShowErrors(false);
			return true;
		}
	}

	const handleSaveDiscipline = async () => {

		const editableSections = calcEditableSections();
		if (!editableSections.length) {
			console.error(`Unable to submit changes as no sections are editable`);
			return;
		}
		const saveObject = buildSavableObject(editableSections);
		const successMsg = `Corrective Action ${!!disciplineId ? 'saved' : 'created'}`;
		const errorMsg = `Error ${!!disciplineId ? 'saving' : 'creating'} Corrective Action`;
		let apiCall;
		if(validateDiscipline(saveObject)) {
			if (!!disciplineId) {
				if(changesRequested !== ''){
					saveObject.changes = changesRequested;
				}
				apiCall = updateDiscipline(disciplineId, saveObject, token);
			} else {
				apiCall = createDiscipline(saveObject, token);
			}
			return apiCall
				.then(() => {
					toast.success(successMsg, {
						position: 'top-right',
						theme: 'colored',
						autoClose: 1000,
					});
					resetModal();
					closeButtonRef.current?.click();
					onSubmit();
				})
				.catch(err => {
					console.error("Error: Update | Create discipline.", err)
				})
		}


	}

	const handleCancelDelete = () => {
		setShowDeleteDialog(false);
	};

	const handleDeleteDraft = () => {
		if (!discipline?.id) {
			return console.error('No discipline ID to delete');
		}

		setShowDeleteDialog(true);
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (discipline?.id) {
			deleteDiscipline(discipline.id, token)
				.pipe(
					catchError(() => errorToast()),
				)
				.subscribe(() => {
					successToast();
					closeButtonRef.current?.click();
					resetModal();
					onSubmit();
				});
		}
		setShowDeleteDialog(false);
	};

	const buildSavableObject = (editableSections: EditableSections[]): Partial<DisciplineCreate> => {
		let rawData: Partial<DisciplineCreate> = {};
		let updatedFields: Partial<DisciplineCreate> = {};
		if (editableSections.includes('body')) {
			if (!disciplineId) {
				rawData.employeeId = employee.id;
			}
			rawData = {
				...rawData,
				date: format(disciplineDate, DateFormatUsingDateFns.PerfectDate),
				infractions: JSON.stringify(getFilteredPriorInfractions()?.map(shrinkInfractionForSaving)),
				stepId: discipline?.step?.id,
				supervisorNote: '',
				templateId: selectedTemplate?.id,
				teamId: employee.teams?.[0].id,
				textFields: JSON.stringify(discipline?.textFields),
				category_id: category?.id!,
				isSigned: false,
				misc: {
					employeeRefusedToSign: employeeRefusedToSign,
					comments: disciplineComments
				}
			}
		}
		// null if it's the No Change option
		if (editableSections.includes('status') && !!selectedAction?.status) {
			rawData.status = selectedAction.status;
		}
		if (editableSections.includes('owner')) {
			rawData.creatorId = discipline.creatorId;
		}
		if (editableSections.includes('reviewer')) {
			rawData.reviewerId = discipline.reviewerId;
		}

		if(user.roles.includes(UserRoleEnum.SuperAdmin)){
			rawData = {
				...rawData,
				misc: {
					comments: disciplineComments,
					employeeRefusedToSign: employeeRefusedToSign,
				}
			};
		}

		// all can be used
		if (!disciplineId) {
			return rawData;
		}
		invariant(savedDisciplineCopy);
		// don't send back data that hasn't changed
		(entries(rawData) as Entries<DisciplineCreate>).forEach(([key, val]) => {
			if (savedDisciplineCopy[key] !== val) {
				updatedFields[key] = val;
			}
		});

		return updatedFields;
	};

	const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const newCategoryId: number = +e.target.value;
		const newCategory = categories.find(cat => cat.id === newCategoryId);
		if (!newCategory) {
			throw new Error(`Unable to find selected category of id ${newCategoryId}`);
		}
		setCategory(newCategory);
		const arr = formErrors.filter(e => e !== 'Category must be selected');
		setFormErrors(arr);
		setDiscipline(prevState => ({
			...prevState,
			categoryId: newCategoryId
		}));
		// TODO: simplify this when we align interfaces with new category system
		if (newCategory.subtype) {
			// clear out the step if switching performance <==> attendance
			if ((newCategory.subtype as unknown as DisciplineStepTypeEnum) !== currentDisciplineType) {
				setDiscipline(prevState => ({
					...prevState,
					step: undefined,
					stepId: undefined,
				}))
			}
			if (newCategory.subtype === CategorySubtypeEnum.Attendance) {
				setCurrentDisciplineType(DisciplineStepTypeEnum.Attendance);
			} else if (newCategory.subtype === CategorySubtypeEnum.Performance) {
				setCurrentDisciplineType(DisciplineStepTypeEnum.Performance);
			}
		}
		setShowAttendanceInfractions(newCategory.subtype === CategorySubtypeEnum.Attendance);
		setSelectedTemplate(undefined);
	};

	const getUpdatedTextFields = (opts?: { date?: string, step?: DisciplineStep, override?: boolean, template?: TemplateModel }): DisciplineTextFields => {
		const template = opts?.template || selectedTemplate;
		const placeholders = generateTemplatePlaceholderValues({
			emp: employee,
			disc: {
				...discipline,
				date: (opts?.date) ? opts.date : moment(disciplineDate).format(SHORT_DATE),
				step: (opts?.step) ? opts.step : discipline?.step,
			},
			latestAtt: priorAttendances[0],
			priorDisc: priorDisciplines[0],
			cmp: user.company,
		});
		if (!template || !discipline || !discipline.textFields) {
			return emptyDiscipline.textFields;
		}
		const tmplFields = template.template.fields;
		// extract values of 3 fields, remove falsey (empty) values and if none remain, update
		if (compact(values(tmplFields)).length === 0 && !opts?.override) {
			return discipline?.textFields;
		} else {
			let tempData = {
				incidentDetails: expand(template.template.fields.incidentDetails, placeholders),
				expectations: expand(template.template.fields.expectations, placeholders),
				consequences: expand(template.template.fields.consequences, placeholders),
			};
			if (areTextFieldsUntouched || opts?.override) {
				return tempData;
			}

			if (tempData.incidentDetails !== discipline?.textFields?.incidentDetails) {
				tempData.incidentDetails = discipline?.textFields?.incidentDetails;
			}
			if (tempData.expectations !== discipline?.textFields?.expectations) {
				tempData.expectations = discipline?.textFields?.expectations;
			}
			if (tempData.consequences !== discipline?.textFields?.consequences) {
				tempData.consequences = discipline?.textFields?.consequences;
			}
			return tempData;
		}
	};

	const handleTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const newTemplateId = +e.target.value;
		if (!category) {
			throw new Error(`Unable to handle template change as no category is set, template ID desired: ${newTemplateId}`);
		}
		const searchTemplate = category.templates.find(tmpl => tmpl.id === newTemplateId);
		if (!searchTemplate) {
			console.error(`Unable to handle template change desired template was not found in the selected category. Looking for ID: ${newTemplateId} in`, category);
			throw new Error(`Unable to handle template change desired template was not found in the selected category. Looking for ID: ${newTemplateId}`);
		}
		setSelectedTemplate(searchTemplate);
		const arr = formErrors.filter(e => e !== 'Template must be selected');
		setFormErrors(arr);
		setDiscipline(prevState => ({
			...prevState,
			templateId: newTemplateId,
			textFields: getUpdatedTextFields({...discipline, override: areTextFieldsUntouched, template: searchTemplate}),
		}));
		setAreTemplateDependenciesAltered(!areTextFieldsUntouched);
	}

	const handleTextFieldChange = (field: DisciplineTextFieldType, e: ChangeEvent<HTMLTextAreaElement>) => {
		setAreTextFieldsUntouched(false);
		if (discipline) {
			const { textFields } = discipline;
			if (textFields) {
				textFields[field] = e.target.value;
				setDiscipline(prevState => ({
					...prevState,
					...textFields,
				}));
			}
		}
	}

	const getAvailableStatusActions = (savedDisc?: Discipline): DisciplineActions[] => {
		const isSuperadmin = user.roles.includes(UserRoleEnum.SuperAdmin);
		const isCreator = !disciplineId || (user.id === savedDisc?.creatorId);
		/**
		 * PRINT: approved
		 * SAVE: Draft/RequestedChange
		 *
		 *
		 * DELETE DRAFT:
		 * Draft/RequestedChange/RequestedApproval/DisciplineDeclined
		 * &&
		 *   (
		 *   		superuser
		 *   	||
		 *   		creator
		 *   )
		 *
		 * DECLINE: RequestedApproval
		 * REQUEST CHANGES: RequestedChange
		 * REQUEST APPROVAL: Draft/RequestedChange
		 * APPROVE: RequestedApproval
		 * ISSUED TO EMPLOYEE: Approved && isActionEnabledForUser()
		 * SUBMITTED TO HR: IssuedToEmployee && isActionEnabledForUser()
		 * RECEIVE BY HR: SubmittedToHr && isActionEnabledForUser()
		 * FAST FILE: !(DocumentationFiled || DisciplineDeclined) superuser
		 */
		const newActionsList: DisciplineActions[] = [];
		if (!disciplineId) {
			newActionsList.push(DisciplineActions.Draft);
		}
		// TODO Pushing fastfile before discipline loads and so still gives option to fastfile on closed CAs
		if (isSuperadmin && ![DisciplineStatusEnum.DisciplineDeclined, DisciplineStatusEnum.DocumentationFiled].includes(discipline?.status)) {
			newActionsList.push(DisciplineActions.FastFile);
		}
		// fast exit so can eliminate a bunch of simple invariant-type checks that savedDisciplineCopy is set
		if (!disciplineId) {
			setAvailableActions(newActionsList);
			return newActionsList;
		}
		invariant(savedDisc, `Unable to process further action checks as no record of server-side data in savedDisciplineCopy.`);
		if (disciplineId) {
			newActionsList.push(DisciplineActions.NoChange);
		}
		if ([
			DisciplineStatusEnum.Draft,
			DisciplineStatusEnum.RequestedChange,
			DisciplineStatusEnum.RequestedApproval,
			DisciplineStatusEnum.DisciplineDeclined].includes(savedDisc.status) && (
			isSuperadmin || isCreator
		)) {
			newActionsList.push(DisciplineActions.Delete);
		}

		if (savedDisc.status === DisciplineStatusEnum.Approved && !isSuperadmin) {
			newActionsList.push(DisciplineActions.Print);
		} else if ([
			DisciplineStatusEnum.DocumentationFiled,
			DisciplineStatusEnum.ReceivedByHr,
			DisciplineStatusEnum.SubmittedToHr,
			DisciplineStatusEnum.IssuedToEmployee,
			DisciplineStatusEnum.Approved
		].includes(savedDisc.status) && (
			isSuperadmin || isCreator
		)) {
			newActionsList.push(DisciplineActions.Print);
		}

		if ([
				DisciplineStatusEnum.Draft,
				DisciplineStatusEnum.RequestedChange,
			] ||
			(
				savedDisc.status === DisciplineStatusEnum.RequestedApproval
				&& isSuperadmin)
		) {
			newActionsList.push(DisciplineActions.Save);
		}
		if (savedDisc.status === DisciplineStatusEnum.RequestedApproval
			&& isSuperadmin) {
			newActionsList.push(DisciplineActions.Decline);
			newActionsList.push(DisciplineActions.RequestChanges);
			newActionsList.push(DisciplineActions.Approve);
		}
		if ([
			DisciplineStatusEnum.Draft,
			DisciplineStatusEnum.RequestedChange
		].includes(savedDisc.status) || !disciplineId) {
			newActionsList.push(DisciplineActions.RequestApproval);
		}
		if (DisciplineStatusEnum.Approved === savedDisc.status) {
			newActionsList.push(DisciplineActions.Issue);
		}
		if (DisciplineStatusEnum.IssuedToEmployee === savedDisc.status) {
			newActionsList.push(DisciplineActions.Submit);
		}
		if (DisciplineStatusEnum.SubmittedToHr === savedDisc.status) {
			newActionsList.push(DisciplineActions.Receive);
		}
		setAvailableActions(newActionsList);
		return newActionsList;
	};

	const handleOnClose = () => {
		if (onClose) {
			onClose(false);
			dispatch(disciplineModalSlice.actions.hide(true));
		}
	};

	useEffect(() => {
		if(show){
			if(owners.length < 1 ){
				if(discipline){
					if(discipline.team || employee.teams ){
						getUsersForOwnerList();
					}
				}
			}
		}
	}, [discipline]);

	return(
		<SkeModal
			title='Corrective Action'
			subtitle={
				<>
					{(!!discipline?.status) &&
						<span className="fs-3 text-muted">Current status: {DisciplineStatusForHumansEnum[discipline.status]}</span>
					}
				</>
			}
			size='lg'
			show={showModal}
			onClose={handleOnClose}
			headerContents={
				<div>
					<h2>
						{user.company?.name}
					</h2>
					<EmployeeBadge
						employee={employee}
						team={discipline?.team}
						size={AvatarSize.md}
						opts={[PersonBadgeOpts.Badge]}
					/>
				</div>
			}
			showFooter={true}
			footerContents={
				<div className="row">
					<div className="col-12">
						<div className="px-6 flex-column flex-md-row">
							{!!reviewers.length && (
								<div className="border-bottom border-bottom-1 pb-5">
									<div className="">
										<div className="d-block d-md-inline-block col-12 col-md-6">
											<label
												className={clsx('d-flex justify-content-between align-items-center',
													{
														'text-danger': !isReviewerValid(),
													})}>
												Reviewer:
												<select
													className="d-inline-flex form-control form-control-lg ms-5"
													disabled={!editableSections.includes('reviewer')}
													value={discipline?.reviewerId || ''}
													onChange={handleChangeReviewer}>
													<option disabled={true}
																	value="">Select Reviewer
													</option>
													{reviewers.map((reviewer: User, idx: number) => {
														return <option
															key={idx}
															value={reviewer.id}>{`${reviewer.firstName} ${reviewer.lastName}`}</option>;
													})}
												</select>
												<button
													className={clsx('btn btn-outline-danger border-danger bg-hover-danger text-hover-light', {
														'invisible': discipline.reviewerId === savedDisciplineCopy?.reviewerId,
													})}
													disabled={discipline.reviewerId === savedDisciplineCopy?.reviewerId}
													onClick={(e: MouseEvent<HTMLButtonElement>) => {
														e.preventDefault();
														setDiscipline(prevState => ({
															...omit(prevState, 'reviewer'),
															reviewerId: savedDisciplineCopy?.reviewerId,
														}));
													}}
												>
													<Icon
														type={Refresh.iconType}
														size="sm"
														icon={Refresh}
													/>
												</button>
											</label>
										</div>
										<div className="d-block d-md-inline-block col-12 col-md-6">
											<label
												className="d-flex justify-content-between align-items-center">
												Owner:
												<select
													className="d-inline-flex form-control form-control-lg ms-5"
													disabled={!editableSections.includes('owner')}
													value={discipline?.creatorId || user.id}
													onChange={handleChangeOwner}>
													<option disabled={true}
																	value="">Select Owner
													</option>
													{owners.map((owner: User, idx: number) => {
														let display = `${owner.firstName} ${owner.lastName}`;
														if (owner.deleted_at) {
															display = `${owner.firstName} ${owner.lastName} (Removed)`;
														}
														return <option
															key={idx}
															disabled={!!owner.deleted_at}
															value={owner.id}
														>
															{display}
														</option>;
													})}
												</select>
												<button
													className={clsx('btn btn-outline-danger border-danger bg-hover-danger text-hover-light', {
														'invisible': discipline.creatorId === savedDisciplineCopy?.creatorId,
													})}
													disabled={discipline.creatorId === savedDisciplineCopy?.creatorId}
													onClick={(e: MouseEvent<HTMLButtonElement>) => {
														e.preventDefault();
														setDiscipline(prevState => ({
															...omit(prevState, 'owner'),
															creatorId: savedDisciplineCopy?.creatorId,
														}));
													}}
												>
													<Icon
														type={Refresh.iconType}
														size="sm"
														icon={Refresh}
													/>
												</button>
											</label>
										</div>
									</div>
									<div>
												<span
													className={clsx(`fst-italic fw-light mt-3 d-block text-wrap align-self-end w-100 text-center`, {
														'd-none': intersection(editableSections, ['reviewer', 'owner']).length === 0,
													})}>Update reviewer/owner by clicking submit below. You can optionally choose to change status at the same time.</span>
										{showErrors && (
											<>
												<div className="d-flex justify-content-end mt-3 me-3">
													<ul className="list-group d-inline-block">
														{formErrors.map((error) => {
															return (
																<li className="list-item text-danger list-unstyled fw-bold">
																	<i className="bi bi-x-octagon text-danger me-2 fw-bold"></i>{error}
																</li>
															);
														})}
													</ul>
												</div>
											</>
										)}
									</div>
									<div
										className={clsx('border-top-1 pt-2', {
											'd-none': selectedAction?.status !== DisciplineStatusEnum.RequestedChange,
										})}>
										<SkeTextbox
											inputId="coram-request-changes"
											label="What changes would you like to see? (optional)"
											labelClasses="fw-bold"
											value={changesRequested}
											onChange={setChangesRequested}
											name="changes" />
									</div>
								</div>
							)}
							<div className="d-flex justify-content-end flex-column flex-md-row">
								{savedDisciplineCopy && [DisciplineStatusEnum.Draft,
									DisciplineStatusEnum.RequestedChange,
									DisciplineStatusEnum.RequestedApproval,
									DisciplineStatusEnum.DisciplineDeclined].includes(savedDisciplineCopy.status) && (
									<>
										{
											(savedDisciplineCopy &&
												(user.roles.includes(UserRoleEnum.SuperAdmin) ||
													(user.roles.includes(UserRoleEnum.Supervisor) &&
														user.id === savedDisciplineCopy.creatorId))
											) && (
												<button
													className="btn btn-outline-danger w-100 w-md-auto mx-0 mx-md-2 my-2"
													onClick={() => handleDeleteDraft()}
												>
													<Icon
														type={Trash.iconType}
														size="sm"
														icon={Trash} />
													Delete Draft
												</button>
											)}
									</>
								)}
								{availableActions.includes(DisciplineActions.Print) && (
									<button
										className="btn btn-outline-primary ms-md-auto w-sm-100 mx-0 mx-md-2 my-2"
										onClick={() => handlePreviewDiscipline()}
										type="button"
									>
												<span>
													<Icon
														type={Printer.iconType}
														size="md"
														icon={Printer} />
													Print
												</span>
									</button>
								)}
								<div className="dropdown mx-0 mx-md-2 my-2">
									<button
										className="btn btn-info dropdown-toggle w-100 w-md-auto d-block"
										type="button"
										id="corrective-action-step-dropdown"
										disabled={!editableSections.includes('status')}
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										{selectedAction?.label || `No status change`}
									</button>
									<ul
										className="dropdown-menu"
										aria-labelledby="corrective-action-step-dropdown"
									>
										{availableActions
											?.filter(act => CA_ACTIONS[act].order !== null)
											.map((act: DisciplineActions, index: number) => {
												const actObj = CA_ACTIONS[act];
												return (
													<li
														key={index}
														onClick={() => setSelectedAction(actObj)}>
														<button className={clsx('dropdown-item', {
															active: selectedAction?.status === actObj.status,
														})}
														>
															{actObj.label}
														</button>
													</li>
												);
											})}
									</ul>
								</div>

								<button
									disabled={editableSections.length === 0
										|| !discipline?.reviewerId
										|| !discipline?.creatorId
										|| !discipline?.step}
									className="btn btn-primary w-100 w-md-auto mx-0 mx-md-2 my-2"
									onClick={() => handleSaveDiscipline()}
									type="button">
									<Icon
										type={Save.iconType}
										size="sm"
										icon={Save} />
									Save
								</button>
							</div>
							<div className="d-flex justify-content-end">
										<span className="text-wrap d-block mx-md-2 w-100 w-md-auto text-center text-md-end">
											<>
												{editableSections.length === 0 && 'Not currently editable for you'}
												{editableSections.length > 0 && (
													<>
														{selectedAction ? selectedAction?.desc : 'Save with no status change'}
													</>
												)}
											</>
										</span>
							</div>
						</div>
					</div>
				</div>
			}
		>
			{(!!discipline && discipline.status === DisciplineStatusEnum.RequestedChange) && (
				<>
					<div className="border-bottom border-bottom-1 pb-5">
						<h3 className="text-danger">Requested Changes:</h3>
						<div className="d-block px-5">
							{discipline.changes}
						</div>
					</div>
					<div className="border-top border-top-1 pb-5">
					</div>
				</>
			)}
			<div className="row">
				<div className="col-12 m-auto">
					<div className="accordion">
						<div className="accordion-item">
							<div
								className="accordion-header"
								id="template-selection-header"
							>
								<button
									className={clsx('accordion-button',
										{
											'text-danger': !isTemplateSelectionValid(),
										})}
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#template-selection-content"
									aria-expanded="true"
									aria-controls="template-selection-content"
								>
									Template Selection
									{!!discipline && !!discipline.templateId && !isNaN(discipline.templateId) && selectedTemplate &&
										<span className="text-dark ms-3">{`${category?.name} > ${selectedTemplate.name}`}</span>
									}
								</button>
							</div>
							<div
								id="template-selection-content"
								className="accordion-collapse collapse show"
								aria-labelledby="template-selection-header"
							>
								<div className="accordion-body">
									<div className="col-lg-8 col-sm-12">
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<label className="label pe-3 fw-bold d-inline-block">
													Category:
												</label>
												<select
													className="d-inline-block form-control form-control-lg"
													value={category?.id || ''}
													disabled={!editableSections.includes('body')}
													onChange={handleCategoryChange}>
													<option disabled={true} value="">Select</option>
													{categories.map((cat, idx) => {
														return <option
															key={idx}
															value={cat.id}>{cat.name}</option>;
													})
													}
												</select>
											</div>
										</div>
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<label
													htmlFor="template-select-box"
													className="label pe-3 fw-bold d-inline-block">
													Template:
												</label>
												<select
													id="template-select-box"
													className="d-inline-block form-control form-control-lg"
													value={selectedTemplate?.id || ''}
													disabled={!editableSections.includes('body')}
													onChange={handleTemplateChange}
												>
													<option disabled={true} value="">Select</option>
													{category?.templates?.length &&
														category?.templates
															.map((tmpl) => {
																return <option
																	key={tmpl.id}
																	value={tmpl.id}>{tmpl.name}</option>;
															})
													}
												</select>
											</div>
										</div>
										<div className="row pb-3">
											<div className="d-flex align-items-center">
												<label className="fw-bold d-inline-block col-2"
															 htmlFor="corrective-action-modal-date-selection">Date:</label>
												<div className="d-flex flex-row-reverse justify-content-end align-items-center">
													{isFutureDateSelected && (
														<span className="fst-italic text-danger d-inline-block ms-7">Future date selected</span>
													)}
													<DatePicker
														id="corrective-action-modal-date-selection"
														dateFormat="MMMM d, yyyy"
														disabled={!editableSections.includes('body')}
														labelClasses="col-form-label fw-light fs-4"
														size="lg"
														selectedDate={disciplineDate}
														onChange={(d) => {
															handleDisciplineDateChange(d);
														}}
														allowFutureDates
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="accordion-item corrective-action-modal">
							<div
								className="accordion-header"
								id="discipline-step-header">
								<button
									className={clsx('accordion-button',
										{
											'text-danger': !discipline?.step?.id,
										})}
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#discipline-step-content"
									aria-expanded="true"
									aria-controls="discipline-step-content">
									Discipline Step<span className="text-dark ms-3">{!!discipline?.step && discipline?.step?.name}</span>
								</button>
							</div>
							<div
								id="discipline-step-content"
								className="accordion-collapse collapse show"
								aria-labelledby="discipline-step-header"
							>
								<div className="accordion-body">
									<p className="text-muted">Displaying <span
										className="fw-bold">{currentDisciplineType}</span> discipline steps</p>
									<div className="discipline-step">
										{
											disciplineSteps?.filter(dsc => dsc.type === currentDisciplineType)
												.map((step: DisciplineStep, idx: number) =>
													<div
														className="chevron-background mb-5"
														key={idx}
														onClick={() => handleDisciplineStepClick(step)}>
														<div
															className={clsx('h-100 chevron-inner d-flex justify-content-center flex-column align-items-center chevron-inner ps-5 pe-9 py-3', {
																active: discipline?.step?.id === step.id,
																disabled: !editableSections.includes('body'),
															})}>
															<span className="warning-step-number d-block fw-boldest fs-5">{step.stepNumber}</span>
															<span className="warning-label ">{step.name}</span>
														</div>
													</div>,
												)}
									</div>

								</div>
							</div>
						</div>
						<div className="accordion-item mb-2 mb-md-5">
							<h2 className="accordion-header" id="infractions-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#infractions-content"
									aria-expanded="true"
									aria-controls="infractions-content">
									Prior Infractions
								</button>

							</h2>
							<div id="infractions-content"
									 className="accordion-collapse collapse show"
									 aria-labelledby="infractions-header">
								<div className="accordion-body">
									<div className="row">
										<div className="d-flex flex-row-reverse align-items-center">
											<ToggleSwitch
												label={`Attendances (${priorAttendances.length})`}
												onChange={(isChecked) => {
													setShowAttendanceInfractions(isChecked);
												}}
												disabled={!editableSections.includes('body')}
												id="hide-infractions"
												isChecked={showAttendanceInfractions}
											></ToggleSwitch>
											{!!(dismissedInfractions.length) && (
												<button
													id="reset-dismissed-infractions"
													className={clsx('btn btn-sm btn-outline-danger no-print me-3',
														{
															disabled: !dismissedInfractions.length,
														})}
													disabled={!editableSections.includes('body')}
													onClick={() => handleResetDismissedInfractions()}>Reset ({dismissedInfractions.length})
												</button>
											)}
										</div>
									</div>
									<div className="row">

										<ul className="list-unstyled previous-disciplines">
											{infractionsToDisplay.map(infraction => {
												switch (infraction.connectionType) {
													// @ts-ignore - migrating to both DISCIPLINE and CORRECTIVE ACTION
													case ConnectionTypeEnumWithCa.CorrectiveAction:
													case ConnectionTypeEnum.Discipline:
														return (
															<li key={`disc-${infraction.id}`}>
																<button
																	className="btn btn-sm btn-outline-dark no-print"
																	disabled={!editableSections.includes('body')}
																	onClick={() => handleHidePriorInfraction(infraction)}>X
																</button>
																<p
																	className="infraction d-inline-block pe-2 mb-1 badge-light-danger">{infraction.date}:</p>
																<span className="fw-bold">{(infraction as Discipline).step?.name}</span>
																<span
																	className="no-print ps-3 fw-light">({infraction.supervisorNote ? infraction.supervisorNote : 'No note'})</span>
															</li>
														);
													case ConnectionTypeEnum.Attendance:
														// don't show attendance records if not in attendance category
														return (
															<li key={`att-${infraction.id}`}>
																<button
																	className="btn btn-sm btn-outline-dark no-print"
																	disabled={!editableSections.includes('body')}
																	onClick={() => handleHidePriorInfraction(infraction)}>X
																</button>
																<p
																	className="infraction d-inline-block pe-2 mb-1 badge-light-warning text-muted">{infraction.date}:</p>
																<span
																	className="fw-bold">{(infraction as Attendance).pointsAssigned} point(s)</span> - {(infraction as Attendance).occurrence}
																<span
																	className="no-print ps-3 fw-light">({infraction.supervisorNote ? infraction.supervisorNote : 'No note'})</span>
															</li>
														);
													default:
														return null;
												}
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="accordion-item mb-2 mb-md-5">
							<h2 className="accordion-header" id="details-header">
								<button
									className={clsx('accordion-button',
										{
											'text-danger': !areTextFieldsValid(),
										})}
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#details-content"
									aria-expanded="true"
									aria-controls="details-content">
									Details
								</button>
							</h2>
							<div id="details-content"
									 className="accordion-collapse collapse show"
									 aria-labelledby="details-header">
								<div className="accordion-body template-fields">
									{areTemplateDependenciesAltered && !areTextFieldsUntouched && (
										<>
											<span className="text-danger fst-italic">Settings have changed, would you like to update text fields to default?</span>
											<button className="btn btn-sm btn-outline-danger ms-2"
															onClick={() => {
																setDiscipline(prevState => ({
																		...prevState,
																		textFields: getUpdatedTextFields({...prevState, override: true}),
																	}),
																);
																setAreTemplateDependenciesAltered(false);
																setAreTextFieldsUntouched(true);
															}}>
												Yes
											</button>
											<button className="btn btn-sm btn-outline-primary ms-2"
															onClick={() => {
																setAreTemplateDependenciesAltered(false);
																setAreTextFieldsUntouched(true);
															}}
											>No
											</button>
										</>
									)}
									<div className="row">
										<label
											className="fw-bolder col-12 pt-5"
											htmlFor="warning-text">Details of the incident</label>
										<textarea
											className="w-100"
											value={discipline?.textFields?.incidentDetails || ''}
											disabled={!editableSections.includes('body')}
											onChange={e => handleTextFieldChange('incidentDetails', e)}
											cols={50}
											rows={5}>
												</textarea>
									</div>

									<div className="row">
										<label
											className="fw-bolder col-12 pt-5"
											htmlFor="warning-text">Expectations for improvement</label>
										<textarea
											className="w-100"
											value={discipline?.textFields?.expectations || ''}
											disabled={!editableSections.includes('body')}
											onChange={e => handleTextFieldChange('expectations', e)}
											cols={50}
											rows={3}>
										</textarea>
									</div>

									<div className="row">
										<label
											className="fw-bolder col-12 pt-5"
											htmlFor="warning-text">If this behavior continues</label>
										<textarea
											className="w-100"
											value={discipline?.textFields?.consequences || ''}
											disabled={!editableSections.includes('body')}
											onChange={e => handleTextFieldChange('consequences', e)}
											cols={50}
											rows={5}>
										</textarea>
									</div>

									<div className="row">
										<label
											className="fw-bolder col-12 pt-5"
											htmlFor="warning-text">Comments</label>
										<textarea
											className="w-100"
											value={disciplineComments}
											onChange={e => {
												setDisciplineComments(e.target.value);
												setDiscipline(prevState => ({
													...prevState,
													misc: {
														comments: e.target.value
													},
												}));

											}}
											disabled={!editableSections.includes('body') && !user.roles.includes(UserRoleEnum.SuperAdmin)}
											cols={50}
											rows={5}>
										</textarea>
									</div>

								{![
									DisciplineStatusEnum.Draft,
									DisciplineStatusEnum.RequestedChange,
									DisciplineStatusEnum.RequestedApproval,
									DisciplineStatusEnum.Approved,
									DisciplineStatusEnum.DisciplineDeclined
								].includes(discipline.status) &&(
									<div className="row pt-4">
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													id="employeeRefusedToSign"
													checked={employeeRefusedToSign}
													disabled={!editableSections.includes('refusalToSign') && !user.roles.includes(UserRoleEnum.SuperAdmin)}
													onChange={e => {
														setEmployeeRefusedToSign(e.target.checked);
														setDiscipline(prevState => ({
															...prevState,
															misc: {
																employeeRefusedToSign: e.target.checked,
															},
														}));

													}}
												/>
												<label className="form-check-label" htmlFor="employeeRefusedToSign">
													Employee refused to sign
												</label>
											</div>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showDeleteDialog && (
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage='Draft deleted'
					message="Are you sure you want to delete this draft?"
				/>
			)}
			<SkeModal
				show={showPrintPreview && !!disciplineSteps?.length}
				onClose={() => setShowPrintPreview(false)}
				title=''
				size='lg'
				showHeader={true}
				headerContents={
					<div className="modal-header justify-content-between">
						{(
							user.roles.includes(UserRoleEnum.SuperAdmin) ||
							![
								DisciplineStatusEnum.Draft,
								DisciplineStatusEnum.RequestedChange,
								DisciplineStatusEnum.DisciplineDeclined
							].includes(discipline.status)
						) && (
							<ReactToPrint
								content={() => pdfCardRef.current}
								trigger={() => <button className="btn btn-primary">Print</button>}
							/>
						)}
					</div>
				}
				showFooter={false}
			>
				{!!discipline && (
					<div
						ref={pdfCardRef}
						className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-md-down modal-dialog-scrollable"
					>
						<div
							id="corrective-action-print-area"
							className=" mx-0 p-10">
							<div className="d-flex justify-content-between">
								<h1 className="display-4 text-left">Corrective Action</h1>
								<h2 className="h4 text-right">{discipline?.company?.name}</h2>
							</div>
							<p>
								The purpose of this documentation is to bring to your attention expectations
								regarding {discipline?.step?.type}. The intent is to define for you the seriousness of the situation
								so that immediate corrective action is taken.
							</p>

							<div>
								<table className="table table-row-bordered border g-3 mb-0">
									<tbody>
									<tr>
										<td>
													<span
														className="fw-bolder">Employee Name: </span>{discipline?.employee?.firstName} {discipline?.employee?.lastName}
										</td>
										<td>
											<span className="fw-bolder"></span>
										</td>
										<td>
											<span className="fw-bolder">Date: </span>{discipline.date}
										</td>
									</tr>
									<tr>
										<td>
											<span className="fw-bolder">Employee ID: </span>{discipline?.employee?.employeeId}
										</td>

										<td>
													<span
														className="fw-bolder">Supervisor: </span>{discipline?.creator?.firstName} {discipline?.creator?.lastName}
										</td>
										<td>
													<span
														className="fw-bolder">Current Points: </span>{discipline.employee?.currentAttendancePoints}
										</td>
									</tr>
									</tbody>
								</table>
							</div>

							<div className="border container mb-3">
								<div className="d-flex justify-content-center">
									<h3>{discipline.step?.type}</h3>
								</div>
								<div className="row">
									<div className="discipline-step d-flex justify-content-between flex-md-wrap flex-wrap">
										{disciplineSteps?.filter(dsc => dsc.type === currentDisciplineType).map((step, idx) =>
											<div
												className="chevron-background pb-3"
												key={idx}>
												<div
													className={clsx('chevron-inner', {
														active: discipline.step?.id === step.id,
													})}>
													<span className="warning-step-number d-block fw-boldest fs-5">{step.stepNumber}</span>
													<span className="warning-label">{step.name}</span>
												</div>
											</div>,
										)}
									</div>

								</div>
							</div>

							<span className="fw-bolder">Details of the Incident</span>
							<p>
								{discipline?.textFields?.incidentDetails}
							</p>

							<span className="fw-bolder">Expectations for Improvement</span>
							<p>
								{discipline.textFields?.expectations}
							</p>

							<span className="fw-bolder">If this behavior continues</span>
							<p>
								{discipline.textFields?.consequences}
							</p>

							<div className="row">
								<p className="fw-bolder">Past Infractions</p>

								<ul className="list-unstyled previous-disciplines">
									{(discipline.infractions as unknown as (Discipline | Attendance)[])?.map((infraction, idx) => {
										switch (infraction.connectionType) {
											case ConnectionTypeEnum.Discipline:
												return (
													<li
														key={idx}>
														<p
															className="infraction d-inline-block pe-2 mb-1 badge-light-danger">{infraction.date}:</p>
														<span className="fw-bold">{(infraction as Discipline)?.step?.name}</span>
													</li>
												);
											case ConnectionTypeEnum.Attendance:
												return (
													<li
														key={idx}>
														<p
															className="infraction d-inline-block pe-2 mb-1 badge-light-warning text-muted">{infraction.date}:</p>
														<span className="fw-bold">{(infraction as Attendance).pointsAssigned} point(s)</span>
														<span className="ms-3 row">
																{(infraction as Attendance).occurrence}
															</span>
													</li>
												);
											default:
												return null;
										}
									})}
								</ul>
							</div>
							<p>
								The above has been discussed with me by my supervisor. I understand the contents and acknowledge and
								understand the corrective action required. I also acknowledge and understand the potential
								consequences
								of noncompliance.
							</p>
							<b>Signatures: </b>
							<table className="table table-row-bordered border g-3">
								<tbody>
								<tr>
									<td>
										Employee:
									</td>
									<td>
										Date:
									</td>
								</tr>
								<tr>
									<td>
										Supervisor:
									</td>
									<td>
										Date:
									</td>
								</tr>
								<tr>
									<td>
										Manager (if applicable):
									</td>
									<td>
										Date:
									</td>
								</tr>
								<tr>
									<td>
										HR:
									</td>
									<td>
										Date:
									</td>
								</tr>
								</tbody>
							</table>
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id="employeeRefusedToSignPrint"
									checked={employeeRefusedToSign}
								/>
								<label className="form-check-label" htmlFor="employeeRefusedToSignPrint">
									Employee refused to sign
								</label>
							</div>
							<br></br>
							<b>Employee Comments: </b>
							{disciplineComments}
						</div>
					</div>
				)}
			</SkeModal>
		</SkeModal>
	)

}